<template>
  <div>
    <!-- search input -->
    <b-progress
      v-if="searching"
      :value="barValue"
      max="100"
    />
    <section id="knowledge-base-search">
      <b-card
        no-body
        class="knowledge-base-bg text-center"
        :style="{backgroundImage: `url(${require('@/assets/images/banner/banner-kb.png')})`}"
      >
        <b-card-body class="card-body">
          <h2 class="text-white">
            The Source For All Your Knowledge
          </h2>

          <!-- form -->
          <b-form class="kb-search-input">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="searchbar"
                v-model="knowledgeBaseSearchQuery"
                placeholder="Ask a question...."
              />
            </b-input-group>

          </b-form>

          <!-- form -->
        </b-card-body>
        <b-button
          class="mt-n4 mb-1 ml-1"
          style="width:250px;"
          :to="{ name: 'pages-knowledge-base-create' }"
        >
          Create New Article
        </b-button>
      </b-card>
    </section>
    <!--/ search input -->

    <section id="knowledge-base-content">

      <!-- content -->
      <b-row class="kb-search-content-info match-height">
        <b-col
          v-for="item in filteredKB"
          :key="item.id"
          md="4"
          sm="6"
          class="kb-search-content"
        >
          <b-card
            class="text-center cursor-pointer"
            :img-src="require('@/assets/images/pages/kb/graphic-' + item.ID + '.png')"
            :img-alt="require('@/assets/images/pages/kb/graphic-' + item.ID + '.png').slice(5)"
            img-width="180"
            img-top
            @click="$router.push({ name: 'pages-knowledge-base-category', params: { category: item.UID } })"
          >
            <h4>{{ item.title }}</h4>
            <b-card-text class="mt-1">
              {{ item.subtitle }}
            </b-card-text>
          </b-card>
        </b-col>
        <b-col
          v-if="kbresults"
          cols="12"
        >
          <b-card v-if="showResults">
            <b-table
              hover
              :items="kbresults"
              :fields="fields"
              class="mb-0"
              @row-clicked="rowClicked"
            >
              <template #cell(customer)="row">
                <router-link :to="'/knowledge-base/company/' + row.item.customer">
                  {{ row.item.customer }}
                </router-link>
              </template></b-table>

          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BForm, BInputGroup, BFormInput, BCardText, BInputGroupPrepend, BProgress, BButton, BTable,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BProgress,
    BButton,
    BTable,
  },
  data() {
    return {
      timer: 0,
      barValue: 0,
      searching: true,
      showResults: false,
      knowledgeBaseSearchQuery: '',
      kbresults: [
        {
          '@search.score': 0,
          ID: '',
          UID: '',
          title: '',
          subtitle: '',
          category: '',
          customer: '',
          lastModifiedBy: '',
          kbCatID: '',
        },
      ],
      kb: {
        queryTime: {
          value: '',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        results: [
          {
            ID: 0,
            UID: '',
            title: '',
            subtitle: '',
          },
        ],
      },
      fields: [
        { key: 'customer', label: 'CUSTOMER' },
        { key: 'title', label: 'TITLE' },
        { key: 'category', label: 'CATEGORY' },
      ],
    }
  },
  computed: {
    filteredKB() {
      const knowledgeBaseSearchQueryLower = this.knowledgeBaseSearchQuery.toLowerCase()
      return this.kb.results.filter(item => item.title.toLowerCase().includes(knowledgeBaseSearchQueryLower) || item.subtitle.toLowerCase().includes(knowledgeBaseSearchQueryLower))
    },
  },
  watch: {
    knowledgeBaseSearchQuery() {
      this.kbresults = {}
      this.getList(this.knowledgeBaseSearchQuery)
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      if (this.barValue === 100) {
        this.barValue = 0
      }
      this.barValue += 5
    }, 600)
  },
  created() {
    this.$http.get('https://asi-api2.azure-api.net/kb/categories?key=7d959c93d0ed474abcdf04804842848a').then(res => { this.kb = res.data; this.searching = false })
  },
  methods: {
    getList(inputValue) {
      if (inputValue.length > 2) {
        this.$http
          .get(
            `https://asi-qbeinvoice.search.windows.net/indexes/articles/docs?api-version=2021-04-30-Preview&search=${
              inputValue
            }*&%24filter=inactive%20ne%20true`,
            {
              headers: {
                'Content-Type': 'application/json',
                'api-key': '80BE6A3D033613C6B21312DA801985E6',
                Accept: 'application/json',
              },
            },
          )
          .then(response => {
            this.kbresults = response.data.value
            this.showResults = true
          })
          .catch(error => {
            console.log(error)
          })
      } else { this.showResults = false }
    },
    rowClicked(items) {
      this.$router.push({
        name: 'pages-knowledge-base-question',
        params: { category: 'KBCAT-0002', slug: items.UID },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
.card-img-top {
    height: 8vw;
    object-fit: scale-down;
    align-content: center;
}
</style>
